import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenciesMarquee from "../components/agencies-marquee"
import ContactFormFields from "../components/contact-form-fields"
import { useRef, useState } from "react"
import submitContactForm from "../utils/contact"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import VariationsSection from "../components/index-page/variations-section"
import BiddingSection from "../components/index-page/bidding-section"
import NextActionsSection from "../components/index-page/next-actions-section"
import CaseStudiesSection from "../components/case-studies-section"
import { Link } from "gatsby"
import RequestDemoSection from "../components/request-demo-section"

const DV360LandingPage = () => {
  const contactForm = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const submitForm = async event => {
    event.preventDefault()
    setIsLoading(true)
    setError("")

    const { success, error } = await submitContactForm(contactForm.current)
    setIsLoading(false)

    if (!success) {
      setError(error)
      return
    }

    contactForm.current.reset()
    setSuccess(true)
  }

  return (
    <Layout
      navigation={[
        {
          to: "#request-demo",
          text: "Request Demo",
          isButton: true,
        },
      ]}
    >
      <SEO
        title="Cookie-Proof DV360 Campaigns with inPowered AI"
        description="Activate AI Decisioning to ensure your programmatic campaigns perform now and in the future."
      />
      <section className="hero dv360-hero">
        <div class="gradient-bg has-background-dark">
          <div class="gradients-container">
            <div class="gradient g1"></div>
            <div class="gradient g2"></div>
            <div class="gradient g3"></div>
            <div class="gradient g4"></div>
            <div class="gradient g5"></div>
          </div>
        </div>
        <div className="hero-body animated fadeIn has-text-centered pt-5">
          <div className="container px-3">
            <div className="columns">
              <div
                className="column is-7"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="animated fadeIn has-text-left title has-text-white has-text-weight-medium">
                  Cookie-Proof<br></br>
                  DV360 Campaigns<br></br>
                </h1>
                <p
                  className="is-size-1-desktop
                  is-size-3-touch has-text-white"
                >
                  with inPowered AI
                </p>
                <h2 className="animated fadeIn has-text-left subtitle has-text-white has-text-weight-light pt-4 is-size-4-desktop">
                  Activate AI Decisioning to ensure your programmatic campaigns
                  perform now and in the future.
                </h2>
              </div>
              <div className="column">
                {/* Card */}
                <div
                  className="card animated fadeIn"
                  style={{ animationDelay: "0.5s", borderRadius: 20 }}
                >
                  <div className="card-content">
                    <h3 className="title is-2 has-text-dark has-text-weight-bold">
                      Request a <span className="has-text-link">Demo</span>
                    </h3>
                    <div className="contact-form has-text-left">
                      <form ref={contactForm}>
                        <input
                          type="hidden"
                          name="formPlacement"
                          value="DV360 (Cookie Version) Landing - Top"
                        />
                        <ContactFormFields
                          success={false}
                          staticFields={{
                            topic: "Request Demo",
                            businessType: "An Agency",
                            message:
                              "I'm interested in learning more about inPowered AI for DV360",
                          }}
                        ></ContactFormFields>

                        {success ? (
                          <button
                            className="button is-rounded is-medium is-link"
                            disabled
                          >
                            Thank you!
                          </button>
                        ) : isLoading ? (
                          <button
                            className="button is-rounded is-medium is-link"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faSpinner}
                              fixedWidth
                              spin
                              size="2x"
                            />
                          </button>
                        ) : (
                          <button
                            className="button is-rounded is-medium is-link"
                            onClick={event => submitForm(event)}
                          >
                            Submit
                          </button>
                        )}

                        <p>{error}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <AgenciesMarquee></AgenciesMarquee>
      </section>

      <div className="home-articles has-background-light py-6">
        <section className="container px-4 ">
          <div className="mb-6">
            <h2 className="title is-2 has-text-weight-medium has-text-dark has-text-centered mb-4">
              AI Increases DV360 Campaign Performance by{" "}
              <span className="has-text-link">50% to 300%</span>
            </h2>
          </div>

          <article className="home-article">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              AI Custom Bidding Models Maximize Media Efficiency
            </h3>
            <p className="description is-size-5 has-line-height-4">
              Learning from historical proprietary data and your first party
              data within your DV360 account, inPowered AI deploys custom
              bidding algorithms to score each impression in real-time and
              adjust bids based on the predicted value of each impression
              resulting in exponentially more efficient media spend. 
            </p>
            <div className="visuals">
              <BiddingSection></BiddingSection>
            </div>
          </article>

          <article className="home-article reverse">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              AI Generates Creatives That Drive Results
            </h3>
            <p className="description is-size-5 has-line-height-4">
              inPowered AI is trained on proprietary post-click engagement data
              to generate the ideal Native creative variations predicted to
              maximize your KPI. All you need to do is select your desired
              outcomes. Your team maintains creative control by reviewing every
              variant and approving them before going live.
            </p>
            <div className="visuals">
              <VariationsSection></VariationsSection>
            </div>
          </article>

          <article className="home-article">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              AI CTAs Significantly Increase Conversion Rates
            </h3>
            <p className="description is-size-5 has-line-height-4">
              inPowered AI places smart CTAs across your campaign landing pages
              mapped back to your objective. By generating the right CTAs at the
              right time, the AI increases landing page conversion rates and
              maximizes your DV360 campaign performance.
            </p>
            <div className="visuals">
              <NextActionsSection></NextActionsSection>
            </div>
          </article>
        </section>
      </div>
      <section className="container px-4 ">
        <div className="my-6 is-flex-desktop is-justify-content-space-between">
          <h2 className="title is-size-2 is-size-3-mobile has-text-weight-medium has-text-dark">
            AI-Powered <span className="has-text-link">Results</span>
          </h2>

          <Link
            to="/case-studies"
            className="button is-rounded is-link is-hidden-touch"
          >
            All Case Studies
          </Link>
        </div>
        <CaseStudiesSection
          limit={2}
          ignoreSlugs={["ohio-liquor", "connecticut-childrens"]}
        />
      </section>

      <div id="request-demo" className="pb-5">
        <RequestDemoSection
          showDescription={false}
          formPlacement="DV360 (Cookie Version) Landing - Bottom"
          staticFields={{
            topic: "Request Demo",
            businessType: "An Agency",
            message:
              "I'm interested in learning more about inPowered AI for DV360",
          }}
        ></RequestDemoSection>
      </div>
    </Layout>
  )
}

export default DV360LandingPage
